.nav-media {
  width: 100%;
  background-color: #47034e;
  height: 50px;
  margin-top: 20px;
  color: white;
}

.media-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  gap: 20px;
}

.links {
  margin-top: 15px;
}

.icon-links {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
}

@media screen and (max-width: 568px) {
  .nav-media {
    display: none;
  }
}

@media screen and (min-width: 568px) and (max-width: 820px) {
  .nav-media {
    display: none;
  }
}
