.main {
  width: 100%;
  /* background-color: #d5bdbd; */
  background-color: #204a64;
  min-height: 60px;
  margin-top: 2px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.main-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  margin-left: auto;
  margin-right: auto;
}

.logo {
  width: 30%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.logo-image {
  border-radius: 12px;
  height: 50px;
  width: 80px;
}

.logo-title {
  font-weight: bold;
  font-size: 18px;
}

.home-link {
  text-decoration: none;
  /* color: black; */
  color: white;
}

.account-p {
  text-decoration: none;
  /* color: black; */
  color: white;
}

.search {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-input--text {
  width: 300px;
  padding: 5px;
  border-radius: 4px;
  outline: none;
  border: none;
}

.search-input--text::placeholder {
  font-size: 14px;
  font-weight: bold;
}

.search-btn {
  padding: 3px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px;
  outline: none;
  border: none;
  background-color: #e82f58;
}

.cart-others {
  width: 30%;
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.tel-icon {
  font-size: 20px;
  margin-right: 10px;
  color:white;
}

.tel-a {
  text-decoration: none;
  /* color: black; */
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.cart {
  gap: 2px;
  display: flex;
}

.shopping {
  font-size: 20px;
  cursor: pointer;
  color:white;

}

.quantity-num {
  color: #e82f58;
  font-weight: 600;
  /* font-size:20px; */
}

.account {
  display: flex;
  gap: 5px;
}

.user {
  font-size: 21px;
  margin-right: 10px;
  color:white;

}

.account-options {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .main-inner {
    flex-direction: column;
  }

  .logo {
    margin-top: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
  }

  .search {
    width: 100%;
    margin-top: 10px;
  }

  .search-input--text {
    width: 250px;
  }

  .cart-others {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (min-width: 568px) and (max-width: 1024px) {
  .main-inner {
    flex-direction: column;
  }

  .logo {
    margin-top: 30px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .search {
    width: 100%;
    margin-top: 30px;
  }

  .cart-others {
    width: 100%;
    margin-top: 20px;
  }
}
