.account-profile {
  width: 100%;
  min-height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-profile-inner {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 50vh;
  /* padding: 20px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.account-my-account-p {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 23px;
}

.title-account {
  display: flex;
  gap: 2rem;
}

.title-account p {
  cursor: pointer;
  padding: 10px;
  border-bottom: 2px solid transparent;
}

.title-account p.active {
  border-bottom: 2px solid #e82f58;
  font-weight: bold;
}

.account-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .account-profile-inner {
    width: 90%;
  }
}

@media screen and (min-width: 568px) and (max-width: 912px) {
  .account-profile-inner {
    width: 90%;
  }
}
