.nav-categories {
  width: 100%;
  /* background-color: #e82f58; */
  background-color: #27363f;

  min-height: 50px;
  margin-top: 2px;
}

.categories {
  width: 50%;
  background-color: #0e222e;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-- {
  width: 80%;
  display: flex;
  color: white;
  justify-content: space-between;
  /* margin-top:10px;  */
  height: 50px;
  padding: 10px;
}

.categories-p {
  font-weight: bold;
  cursor: pointer;
}

.categories-links {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 568px) {
  .categories {
    width: 100%;
  }
  .categories-- {
    width: 100%;
    padding: 2px;
  }
}

@media screen and (min-width: 568px) and (max-width: 1280px) {
  .categories {
    width: 90%;
  }
  .categories-- {
    width: 90%;
  }
}
