.single-product-s {
  width: calc(100% - 100px);
  margin: 50px 5%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.image-container {
  width: 30% !important;
  min-width: 400px;
  overflow: hidden;
  display: inline-block;
  height: 500px;
}
.quantity {
  display: flex;
  align-items: center;
  gap: 10px;
}

.single-product-image-s {
  cursor: zoom-in;
  transition: transform 0.5s ease;
  width: 100%;
  height: auto;
  aspect-ratio: auto 185 / 185;
  overflow-clip-margin: content-box;
  overflow: clip;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.single-product-image-s:hover {
  transform: scale(1.5);
}

.product-desc {
  width: 45% !important;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}
@media (max-width: 480px) {
  .product-desc {
    width: 100% !important;
  }
}

.product-desc-s {
  border-bottom: 1px solid #ccc;
  padding-top: 1rem;
}

.product-desc-s p {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.35rem;
  overflow-wrap: break-word;
}

.product-price {
  margin-top: 30px;
}

.actual-price {
  font-weight: 700;
  font-size: 19px;
}

.status {
  margin-top: 10px;
}

.toggle-status {
  color: green;
  font-weight: bold;
}

.textarea {
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
}

textarea {
  padding: 5px;
  margin-bottom: 10px;
}

.product-btns {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* justify-content:space-between; */
}

.cart-btn-div button {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  border: none;
  background-color: black;
  color: white;
}

.buy-now-div button {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  border: none;
  background-color: #d5bdbd;
}

.order-via-wa {
  margin-top: 30px;
}

.wa-link {
  text-decoration: none;
}

.wa-link button {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  border: none;
  background-color: green;
  color: white;
  transition: background-color 0.5s ease-in-out 0.3ms;
}

.wa-link button:hover {
  background-color: #22c35e;
}
@media (max-width: 480px) {
  .related-categories-s {
    width: 100% !important;
  }
  .related-categories--image {
    width: 100% !important;
  }
}

.related-categories-s {
  width: 30%;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.related-categories-s p {
  /* font-weight: 600;
  font-size: 21px; */

  font-weight: 800;
  margin-top: 10px;
  cursor: pointer;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  cursor: pointer;
  display: -webkit-box;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1rem !important;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.related-categories--image {
  width: 200px;
}

.related-categories-- {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.image-category-s {
  width: 100%;
  height: 130px;
  object-fit: cover;
  cursor: pointer;

  width: 100%;
  height: auto;
  aspect-ratio: auto 185 / 185;
  overflow-clip-margin: content-box;
  overflow: clip;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.category-desc---- {
  font-weight: 600;
  margin-top: 20px;
  overflow-wrap: break-word;
}

.load-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .single-product {
    flex-direction: column;
  }

  .image-container {
    display:flex;
    flex-direction:column;
    /* justify-content: center; */
    align-items: center;
    margin-left:auto;
    margin-right:auto;
    width: 100%;
  }

  .single-product-image-s {
    width: 350px;
  }

  .product-desc {
    width: 100%;
    margin-top: 30px;
  }

  .product-desc-- p {
    text-align: center;
  }
  .related-categories {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
  }
  textarea {
    width: 400px;
  }
}

@media screen and (min-width: 568px) and (max-width: 1280px) {
  .single-product {
    flex-direction: column;
    /* justify-content:center;
        align-items:center; */
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .product-desc {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-desc-- p {
    text-align: center;
  }
  .related-categories {
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100% !important;
}
.swiper-wrapper {
  display: flex;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
