.body {
  width: 100%;
  min-height: 100vh;
  margin-top: 30px;
}

.body-inner {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.sort {
  width: 100%;
  background-color: #e82f58;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

#sortOptions {
  width: 200px;
  padding: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

.products {
  margin-top: 30px;
}

/* .actual-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
} */
.prod-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.product {
  box-sizing: border-box;
  width: 100%;
}
.product-image-wrapper {
  width: 100%;
  height: 240px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.product-image {
  width: 100%;
  height: auto;
  aspect-ratio: auto 185 / 185;
  overflow-clip-margin: content-box;
  overflow: clip;
  object-fit: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.3s ease-in-out 0.2ms;
  border-radius: 2px;
}

.product img:hover {
  transform: scale(1.1);
}

.product-link {
  width: 150px;
  text-decoration: none;
  color: #e82f58;
  padding: 5px;
  background-color: #fff6ed;
}

.product-p {
  font-weight: 800;
  margin-top: 10px;
  cursor: pointer;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  cursor: pointer;
  display: -webkit-box;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1rem !important;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.price {
  margin-top: 10px;
  font-weight: bold;
}

.pagination {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.back {
  background-color: #d5bdbd;
  padding: 8px;
  cursor: pointer;
}

.arrow-back {
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
}

.page-number {
  font-size: 21px;
}

.back-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.arrow-top {
  font-size: 23px;
  cursor: pointer;
  color: green;
}

@media screen and (max-width: 568px) {
  .body-inner {
    width: 100%;
  }

  .actual-products {
    flex-direction: column;
  }
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .prod-list-wrapper {
    width: calc(100% - 10px);
    flex-direction: row;
  }
}
